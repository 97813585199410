<template>
  <v-card :loading="loading">
    <v-container>
      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-title>{{ $i18n.t("salesPerson.title") }}</v-card-title>
        </v-col>

        <v-col cols="12" sm="6">
          <v-text-field
            name="search"
            :placeholder="$t('salesPerson.search')"
            v-model="form.search"
            clearable
            append-icon="mdi-magnify"
            @input="searchInput"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>

    <v-data-table
      :headers="headers"
      :items="filteredSalesPerson"
      disable-pagination
      hide-default-footer
      disable-sort
    >
      <template v-slot:item.address.country="{ item }">
        <CountryFlag
          :country="getItemByIso2(item.address.country)"
        ></CountryFlag>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn @click="openOrderDialog(item)" icon>
          <v-icon>mdi-arrow-right</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-card-actions
      v-if="hasPrevPage || hasNextPage"
      class="d-flex justify-space-between"
    >
      <v-btn
        icon
        tile
        color="primary"
        :disabled="!hasPrevPage"
        @click="prevPage"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>

      <span class="grey--text text--darken-2 font-weight-bold">
        {{ currentPage + 1 }}
      </span>

      <v-btn
        icon
        tile
        color="primary"
        :disabled="!hasNextPage"
        @click="nextPage"
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-card-actions>
    <SalesPersonOrder ref="salesPersonOrder"></SalesPersonOrder>
  </v-card>
</template>

<script>
/* eslint-disable */
import _ from "lodash";
import Repository from "../store/api/repository";
import { mapState, mapGetters } from "vuex";
import CountryFlag from "@/components/CountryFlag";
import SalesPersonOrder from "@/components/SalesPersonOrder";

export default {
  name: "PanelSalesPerson",
  components: {
    CountryFlag,
    SalesPersonOrder
  },
  data() {
    return {
      form: {
        search: null
      },
      salesPerson: [],
      loading: true,
      itemsPerPage: 10,
      currentPage: 0
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user
    }),
    ...mapGetters({
      getItemByIso2: "nations/getItemByIso2"
    }),
    headers() {
      return [
        {
          text: this.$i18n.t("salesPerson.table.company"),
          value: "company"
        },
        {
          text: this.$i18n.t("salesPerson.table.country"),
          value: "address.country"
        },
        {
          value: "actions",
          sortable: false,
          width: "1%"
        }
      ];
    },
    filteredSalesPerson() {
      return this.salesPerson
        ? this.salesPerson.slice(0, this.itemsPerPage)
        : null;
    },
    hasPrevPage() {
      return this.currentPage !== 0;
    },
    hasNextPage() {
      return this.itemsPerPage < this.salesPerson.length;
    }
  },
  methods: {
    prevPage() {
      this.currentPage -= 1;
      this.fetchPage(this.form.search ? this.form.search : "");
    },
    nextPage() {
      this.currentPage += 1;
      this.fetchPage(this.form.search ? this.form.search : "");
    },
    searchInput: _.debounce(function(value) {
      this.currentPage = 0;
      this.fetchPage(value ? value : "");
    }, 250),
    fetchPage(search = "") {
      const limit = this.itemsPerPage + 1;
      const offset = this.itemsPerPage * this.currentPage;

      Repository.get(
        `users/${this.user.id}/timingpoints/salespersons?limit=${limit}&offset=${offset}&search=${search}`
      )
        .then(response => {
          this.salesPerson = response.data.data;
          this.loading = false;
        })
        .catch(response => {
          this.loading = false;
        });
    },
    openOrderDialog(salesPerson) {
      this.$refs.salesPersonOrder.openDialog(salesPerson);
    }
  },
  created() {
    this.fetchPage();
  }
};
</script>
