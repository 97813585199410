<template>
  <v-dialog
    v-if="salesPerson"
    v-model="showDialog"
    width="500"
    @click:outside="closeDialog"
  >
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>
          {{ $t("forms.salesPersonOrder.title") }}
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-form v-if="!success" @submit.prevent="submitForm" class="mx-2">
        <v-container>
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                type="email"
                :label="$t('forms.general.email.label')"
                v-model="form.email"
                :error-messages="emailErrors"
                readonly
                append-icon="mdi-pencil-off"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-select
                type="number"
                :label="$t('forms.salesPersonOrder.tpAmount')"
                v-model="form.tpAmount"
                :error-messages="tpAmountErrors"
                :items="packages"
              ></v-select>
            </v-col>

            <v-col cols="12">
              <v-alert v-if="error" dense outlined type="error">
                {{ error }}
              </v-alert>
            </v-col>
          </v-row>
        </v-container>

        <v-card-actions class="d-flex justify-space-between">
          <v-btn @click="closeDialog" color="error" text>
            {{ $t("forms.salesPersonOrder.cancel") }}
          </v-btn>

          <v-btn type="submit" color="success" text :loading="loading">
            {{ $t("forms.salesPersonOrder.submit") }}
          </v-btn>
        </v-card-actions>
      </v-form>
      <div v-else>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-alert v-if="success" dense outlined type="success">
                {{ success }}
              </v-alert>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions class="d-flex justify-end">
          <v-btn @click="closeDialog" text>
            {{ $t("forms.salesPersonOrder.close") }}
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
/* eslint-disable */

import Repository from "../store/api/repository";
import { mapState } from "vuex";
import { required, email } from "vuelidate/lib/validators";

export default {
  name: "SalesPersonOrder",
  data() {
    return {
      showDialog: false,
      salesPerson: null,
      packages: [
        {
          value: 5000,
          text: "5000"
        },
        {
          value: 10000,
          text: "10000"
        },
        {
          value: 20000,
          text: "20000"
        }
      ],
      form: {
        email: null,
        tpAmount: 5000
      },
      loading: false,
      success: null,
      error: null
    };
  },
  validations() {
    return {
      form: {
        email: {
          required,
          email
        },
        tpAmount: {
          required
        }
      }
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user
    }),
    emailErrors() {
      const errors = [];
      if (!this.$v.form.email.$dirty) return errors;
      !this.$v.form.email.required &&
        errors.push(this.$i18n.t("forms.general.email.errors.required"));
      !this.$v.form.email.email &&
        errors.push(this.$i18n.t("forms.general.email.errors.format"));
      return errors;
    },
    tpAmountErrors() {
      const errors = [];
      if (!this.$v.form.tpAmount.$dirty) return errors;
      !this.$v.form.tpAmount.required &&
        errors.push(
          this.$i18n.t("forms.salesPersonOrder.errors.tpAmount.required")
        );
      return errors;
    }
  },
  methods: {
    openDialog(salesPerson) {
      this.initData(salesPerson);
      this.showDialog = true;
    },
    closeDialog() {
      this.showDialog = false;
      this.resetData();
    },
    initData(salesPerson) {
      this.salesPerson = salesPerson;
      this.form.email = salesPerson.email;
    },
    resetData() {
      this.loading = false;
      this.success = null;
      this.error = null;
    },
    submitForm() {
      this.$v.form.$touch();

      if (!this.$v.form.$invalid) {
        this.loading = true;
        this.success = null;
        this.error = null;

        const payload = {
          email: this.form.email,
          timingPoints: this.form.tpAmount
        };

        Repository.post(`users/${this.user.id}/timingpoints/order`, payload)
          .then(response => {
            this.loading = false;

            if (response.data.status !== 0) {
              return Promise.reject(response);
            }

            this.success = this.$i18n.t("forms.salesPersonOrder.success", {
              amount: this.form.tpAmount
            });
          })
          .catch(response => {
            switch (response.data.status) {
              case -5000:
                this.error = this.$i18n.t(
                  "forms.salesPersonOrder.errors.tpAccountMissing"
                );
                break;
              case -5002:
                this.error = this.$i18n.t(
                  "forms.salesPersonOrder.errors.invalidEmail"
                );
                break;
              default:
                this.error = this.$i18n.t("errors.unexpectedError");
            }
            this.loading = false;
          });
      }
    }
  }
};
</script>
